import axios from 'axios';
import { store } from '@/store'
let apiUrl = ''
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.kasagame.com'
} else {
    apiUrl = 'http://localhost:3000'
   }

export default {
    productLike(data) {
        return axios.post(apiUrl + '/api/product-like', data)
            .then(response => response.data)
    },
    checkPaymentLink(data) {
        return axios.post(apiUrl + '/api/check-link', data)
            .then(response => response.data)
    },
    gameDetail(data) {
        return axios.post(apiUrl+ '/api/game-detail', data)
            .then(response => response.data)
    },
    checkProductId(data){
        return axios.post(apiUrl+ '/api/product-check', data)
            .then(response => response.data)
    },
    paymentMethods(data) {
        return axios.post(apiUrl+ '/api/payment-methods', data)
            .then(response => response.data)
    },
    paymentChannelDetail(data){
        return axios.post(apiUrl+ '/api/payment-channel-detail', data)
            .then(response => response.data)
    },
    getPaymentLink(data) {
        return axios.post(apiUrl+ '/payments/api-pay', data)
            .then(response => response.data)
    },
    getOrders(data){
        return axios.post(apiUrl+'/api/orders', data)
            .then(response => response.data)
    },
    getHistory(data){
        return axios.post(apiUrl+'/api/history', data)
            .then(response => response.data)
    },
    campaigns(data){
        return axios.post(apiUrl+'/api/product-campaigns', data)
            .then(response => response.data)
    },
    westernUnion(data){
        return axios.post(apiUrl+'/payments/western-union-form', data)
            .then(response => response.data)
    },
    getToken() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.get(apiUrl+'/user/getToken')
            .then(response => response.data)
    },
    userLogin(data) {
        return axios
            .post(apiUrl+'/user/login', data)
            .then((response) =>{
                return response.data
            })
    },
    logout() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios
            .get(apiUrl+'/user/logout')
            .then((response) => {
                return response
            })
    },
    westernInformation(){
        return axios
            .get(apiUrl+'/api/western-union')
            .then((response) => {
                return response.data
            })

    },
    swiftBankDetail(data){
        return axios
            .post(apiUrl+'/api/swift-bank-detail', data)
            .then((response) => {
                return response.data
            })

    },
    sendSwiftForm(data){
        return axios.post(apiUrl+'/payments/swift-request-form', data)
            .then(response => response.data)
    },


}