import Vue from 'vue'
import App from './App.vue'
  import router from "@/router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment'
import {store} from '@/store'

Vue.config.productionTip = false
Vue.filter("currency",(value) => {
  return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2, maximumFractionDigits: 2})
});
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

//styles
require('./assets/css/bootstrap.css')
require('./assets/fontawesome/css/all.css')
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('./assets/css/fontawesome.min.css')
require('./assets/css/style.css')
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
