<template>
  <b-modal id="wallet-login"   hide-footer hide-header size="lg" >
    <section class="nft-contact-area pb-120" :style="{'background-image': 'url('+require('../assets/images/login-bg.png')+')'} ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="contact-form">
              <div class="title-txt text-center pt-3">
                <h2>Kasagame Hesabınızla giriş yapın</h2>
              </div>
              <div class="nft-form-area">
                <form @submit.prevent="signIn">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-field"><input v-model="email" type="email" placeholder="Eposta Adresiniz"></div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-field"><input v-model="password" type="password" placeholder="Şifreniz"></div>
                    </div>
                    <p class="text-white">Hesabın yok mu? <a href="https://kasagame.com/register" target="_blank">Hemen Kayıt Ol</a></p>

                    <div class="col-md-12 d-flex justify-content-center">
                      <div class="submit-btn"><button type="submit" v-if="!loading">Giriş Yap</button></div>
                      <div class="submit-btn"><button type="submit" v-if="loading"><i class="fas fa-spinner fa spin"></i></button></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </b-modal>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "WalletLogin",
  data() {
    return {
      key: this.$route.params.key,
      keyErr : false,
      gameDetail: {
        title: '',
        products: [],
      },
      loading: false,
      orders: [],
      walletBalance: 0,
      email: '',
      password: ''
    }
  },
  methods: {
    getToken() {
      apiService.getToken()
          .then((res) => {
            if(res.state){
              this.walletBalance = res.user.balance
            }else{
              this.$store.dispatch('updateUser', {
                token: '',
                balance: 0,
                login: false,
              })
            }
          })
    },
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    checkKey() {
      apiService.checkPaymentLink({key: this.key})
          .then((res) => {
            if(res.state) {
              this.keyErr = false
            }
            else {
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    gameDetails() {
      apiService.gameDetail({key: this.key})
          .then((res) => {
            if(res.state){
              this.gameDetail = res.data
            }else{
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    signIn() {
      this.loading = true
      apiService.userLogin({email: this.email, password: this.password})
          .then((res) => {
            this.loading = false
            if(res.state){
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: 'Başarıyla giriş yaptınız',
                text: '',
              });
              this.$bvModal.hide('wallet-login')
              this.$store.dispatch('updateUser', {
                token: res.token,
                balance: res.user.balance,
                username: res.user.username,
                profilePic: res.user.profilePic,
                login: true,
              })
            }else {
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: '',
                text: res.message,
              });
            }
          })
    },
    logout(){
      apiService.logout()
          .then(() => {
            this.$router.push({path: '/'+this.key})
          })
    }
  },
  created() {
    this.key = this.$route.params.key
    this.checkKey()
    this.gameDetails()
    if(this.$store.getters.user.login){
      this.getToken()
    }

  },
  watch: {
    keyErr : function () {
      if(this.keyErr)
        setTimeout(() => {
          window.location.href = 'https://kasagame.com'
        }, 1500)
    }
  }

}
</script>

<style scoped>

</style>