<template>
  <div>
    <div class="main-wrapper">
      <header-new :title="gameDetail.title" :return_id="gameDetail.return_id" :apiKey="key"></header-new>
      <section class="nft-breadcrumb" style=""></section>
      <section class="creator-profile-area position-relative overflow-hidden">
        <img :src="require('../assets/images/shapes/hero-top-left.svg')" alt="shape" class="shape-top-left position-absolute"/>
        <img :src="require('../assets/images/shapes/dark-mode-gradient.svg')" alt="shape" class="position-absolute center-shape"/>
        <div class="creator-profile-tab pb-120">
          <div class="container">
            <div class="row">
              <div class="ctp-tab">
                <ul class="nav nav-tabs">
                  <li>
                    <button data-bs-toggle="tab" data-bs-target="#edit_profile" type="button" :class="{'active' : tab === 1}" @click="tab = 1">Tüm ürünler</button>
                  </li>
                  <li>
                    <button data-bs-toggle="tab" data-bs-target="#profile_liked" type="button" :class="{'active' : tab === 2}" @click="tab = 2">Sınırlı Sayıda </button>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane fade show " :class="{'active' : tab === 1}">
                    <div class="row g-4" v-if="productLoading">
                      <div class="col-lg-4 col-xl-3 col-md-6" v-for="index in 8" :key="index" >
                        <div class="single-nft-item" >
                          <div class="nft-item-wrapper rounded">
                            <a class="item-image position-relative" >
                              <b-skeleton-img></b-skeleton-img>
                            </a>
                            <div class="buy-now-btn primary-btn" >
                              <a  > Satın Al</a>
                            </div>
                            <div class="nft-item-content-wrap pt-4">
                              <a href="#">
                                <h2 class="h6"><b-skeleton></b-skeleton></h2>
                              </a>
                              <hr/>
                              <div class="nft-item-footer d-flex align-items-center justify-content-between">
                                <div class="item-price-wrapper d-flex align-items-center">
                                 <span>
                                      <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                 </span>
                                  <div class="item-price ms-3">
                                    <p class="mb-0">
                                      <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                    </p>
                                  </div>
                                </div>
                                <span class="item-like">
                                  <i class="fas fa-solid fa-heart"></i>
                                       <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                  <!-- -->
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row g-4">
                      <div class="col-lg-4 col-xl-3 col-md-6" v-for="(item, index) in gameDetail.products" :key="index">
                        <div class="single-nft-item">
                          <div class="nft-item-wrapper rounded">
                            <router-link class="item-image position-relative" :to="{ path: 'payment-methods/'+item.id+'/'+key}">
                              <img :src="gameDetail.image"  class="img-fluid rounded-top"/>
                            </router-link>
                            <div class="buy-now-btn primary-btn">
                              <router-link :to="{ path: 'payment-methods/'+item.id+'/'+key}"> Satın Al</router-link>
                            </div>
                            <div class="nft-item-content-wrap pt-4">
                              <router-link :to="{ path: 'payment-methods/'+item.id+'/'+key}">
                                <h2 class="h6">{{ item.title }}</h2>
                                <h5 class="h6 text-success" v-if="item.campaigns.length > 0">
                                  <i class="fas fa-gift"></i> {{ item.campaigns[0].title }}
                                </h5>
                                <h5 class="h6 text-danger" v-if="item.campaigns.length > 0">
                                  <i class="fas fa-clock"></i>
                                   <i v-if="item.duration.days > 0">{{ item.duration.days }} Gün</i>  {{ item.duration.hours }}:{{item.duration.minutes}}:{{item.duration.seconds}}
                                </h5>
                              </router-link>
                              <hr/>
                              <div class="nft-item-footer d-flex align-items-center justify-content-between">
                                <div class="item-price-wrapper d-flex align-items-center">
                                 <span>
                                   <i class=" fa-solid fa-turkish-lira-sign"></i>
                                 </span>
                                  <div class="item-price ms-3">
                                    <p class="mb-0">
                                      <i v-b-tooltip:v-b-hover title="Kampanyalar yükleniyor.." class="fas fa-spinner fa-spin" v-if="item.campaignLoading"/>
                                    </p>
                                    <p class="mb-0 text-danger" v-if="item.api_stock">
                                      <i class="fas fa-clock"></i> Sınırlı sayıda
                                    </p>

                                    <h5 class="mb-0">{{ item.price | currency }} TL</h5>
                                  </div>
                                </div>
                                <span class="item-like"  @click="productLike({id: item.id, index: index})">
                                  <i class="fas fa-solid fa-heart"></i>
                                    {{ item.likes }} Beğeni
                                  <!-- -->
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade show " :class="{'active' : tab === 2}">
                    <div class="row g-4" v-if="productLoading">
                      <div class="col-lg-4 col-xl-3 col-md-6" v-for="index in 8" :key="index" >
                        <div class="single-nft-item" >
                          <div class="nft-item-wrapper rounded">
                            <a class="item-image position-relative" >
                              <b-skeleton-img></b-skeleton-img>
                            </a>
                            <div class="buy-now-btn primary-btn" >
                              <a href="#"> Satın Al</a>
                            </div>
                            <div class="nft-item-content-wrap pt-4">
                              <a href="#">
                                <h2 class="h6"><b-skeleton></b-skeleton></h2>
                              </a>
                              <hr/>
                              <div class="nft-item-footer d-flex align-items-center justify-content-between">
                                <div class="item-price-wrapper d-flex align-items-center">
                                 <span>
                                      <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                 </span>
                                  <div class="item-price ms-3">
                                    <p class="mb-0">
                                      <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                    </p>
                                  </div>
                                </div>
                                <span class="item-like">
                                  <i class="fas fa-solid fa-heart"></i>
                                       <i v-b-tooltip:v-b-hover  class="fas fa-spinner fa-spin" />
                                  <!-- -->
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row g-4">
                      <div class="col-lg-4 col-xl-3 col-md-6" v-for="(item, index) in stocks_products" :key="index">
                        <div class="single-nft-item">
                          <div class="nft-item-wrapper rounded">
                            <router-link class="item-image position-relative" :to="{ path: 'payment-methods/'+item.id+'/'+key}">
                              <img :src="gameDetail.image" alt="nft item" class="img-fluid rounded-top"/>
                            </router-link>
                            <div class="buy-now-btn primary-btn">
                              <router-link :to="{ path: 'payment-methods/'+item.id+'/'+key}"> Satın Al</router-link>
                            </div>
                            <div class="nft-item-content-wrap pt-4">
                              <router-link :to="{ path: 'payment-methods/'+item.id+'/'+key}">
                                <h2 class="h6">{{ item.title }}</h2>
                              </router-link>
                              <hr/>
                              <div class="nft-item-footer d-flex align-items-center justify-content-between">
                                <div class="item-price-wrapper d-flex align-items-center">
                                 <span>
                                   <i class=" fa-solid fa-turkish-lira-sign"></i>
                                 </span>
                                  <div class="item-price ms-3">
                                    <p class="mb-0">
                                      <i v-b-tooltip:v-b-hover title="Kampanyalar yükleniyor.." class="fas fa-spinner fa-spin" v-if="item.campaignLoading"/>
                                    </p>
                                    <p class="mb-0 text-danger" v-if="item.api_stock">
                                      <i class="fas fa-clock"></i> Sınırlı sayıda
                                    </p>
                                    <p class="mb-0 text-success" v-if="item.campaigns.length > 0 ">
                                      <i class="fas fa-gift"></i> Kampanyalı ürün
                                    </p>

                                    <h5 class="mb-0">{{ item.price | currency }} TL</h5>
                                  </div>
                                </div>
                                <span class="item-like" @click="productLike({id: item.id, index: index})">
                                  <i class="fas fa-solid fa-heart"></i>
                                    {{ item.likes }} Beğeni
                                  <!-- -->
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img :src="require('../assets/images/shapes/hero-top-right.svg')" alt="shape" class="shape-right-top position-absolute"/>
      </section>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import HeaderNew from "@/components/HeaderNew";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  components: {HeaderNew, Footer},
  data() {
    return {
      key: this.$route.params.key,
      keyErr : false,
      gameDetail: {
        title: '',
        products: [],
      },
      productLoading: true,
      tab: 1,
      stocks_products: []
    }
  },
  methods: {
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    checkKey() {
      apiService.checkPaymentLink({key: this.key})
      .then((res) => {
        if(res.state) {
          this.keyErr = false
        }
        else {
          this.errorMessage(res.message)
          this.keyErr = true
        }
      })
    },
    gameDetails() {
      this.productLoading = true
      apiService.gameDetail({key: this.key})
      .then((res) => {
        this.productLoading = false
        if(res.state){
          let products = res.data.products
          res.data.products = []
          this.gameDetail = res.data
          products.map((product) => {
            product.duration = {days: 0, hours: 0, minutes: 0, seconds: 0}
            if(product.api_stock)
              this.stocks_products.push(product)
            console.log(product.duration)
            this.gameDetail.products.push(product)
            this.campaigns(product.id)
          })
        }else{
          this.errorMessage(res.message)
          this.keyErr = true
        }
      })
    },
    campaigns(index) {
      let findIndex = this.gameDetail.products.findIndex(e => e.id === index)
      if(findIndex > -1) {
        this.gameDetail.products[findIndex].campaignLoading = false
        apiService.campaigns({key: this.key, id: this.gameDetail.products[findIndex].id})
            .then((res) => {
              this.countTimer({date: res.data[0].end_date, index: index})
              this.gameDetail.products[findIndex].campaignLoading = false
              if (res.state) {
                this.gameDetail.products[findIndex].campaigns = res.data
              }
            })
      }
    },
    productLike(data){
      let findIndex = this.$store.getters.productLikes.findIndex(e => e.id === data.id)
      if(findIndex < 0){
        let productsLikes  = this.$store.getters.productLikes
        productsLikes.push({id: data.id})
        this.$store.dispatch('getLikes', productsLikes)
        this.gameDetail.products[data.index].likes = parseInt(this.gameDetail.products[data.index].likes) + 1
        apiService.productLike({id: data.id})
      }

    },
    countTimer(time){
      const update = (val) => {
        let findIndexT = this.gameDetail.products.findIndex(e => e.id === val.index)
        this.gameDetail.products[findIndexT].duration = val.duration
      }
      setInterval(function() {
        let eventTime = new Date(time.date).getTime();
        let currentTime = new Date(Date.now()).getTime();
        let distance = eventTime - currentTime;
        // Time calculations for days, hours, minutes and seconds
        let days = Math.floor(distance / (1000 * 60 * 60 * 24));
        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((distance % (1000 * 60)) / 1000);
        let duration_ = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds
        }
        update({duration: duration_, index: time.index})
      }, 1000);
    },

  },
  created() {
    this.key = this.$route.params.key
    this.checkKey()
    this.gameDetails()
  },
  watch: {
    keyErr : function () {
      if(this.keyErr)
        setTimeout(() => {
          window.location.href = 'https://kasagame.com'
        }, 1500)
    }
  }
}
</script>

<style scoped>

</style>