<template>
  <header>
    <div class="container">
      <div class="row no-gutters">
        <div class="mobile-menu">
          <div class="hamburger hamburger--spring" @click="toggle">
            <span class="hamburger-box">
              <span
              class="hamburger-inner">
              </span>
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-12 d-flex align-items-center">
          <div class="logo-kasagame"><router-link :to="{path: '/'+apiKey}"></router-link></div>
        </div>
        <div class="col-md-10" >
          <div class="col-lg-3 col-12" v-if="$store.getters.wallet" >
            <div class="kasagame-balance" >
              <div class="content" >
                <router-link :to="{path: '/wallet/'+apiKey}">
                  <span class="name" >Cüzdanınız:</span>
                  <div>
                    <span class="balance" v-if="balanceLoading"><b-skeleton width="100%"></b-skeleton></span>
                    <span class="balance" v-else>{{ walletBalance | currency}} TL</span><span class="add" ><i class="fas fa-plus-circle" ></i></span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="user-action-content">
            <div class="order-history">
              <router-link :to="{path: '/orders/'+apiKey}">
                      <span class="ico">
                        <i class="fa fa-check text-dark"></i>
                      </span>
                <span class="title">SİPARİŞLERİM</span>
              </router-link>
            </div>
            <div class="order-payments">
              <router-link :to="{path: '/history/'+apiKey}">
                      <span class="ico">
                        <i class="fas fa-history"></i>
                      </span>
                <span class="title">
                        GEÇMİŞ ÖDEMELERİM
                      </span>
              </router-link>
            </div>
            <div class="user-infos" style="margin-right: 10px">
              <div class="info">
                <span class="title">Mağaza :</span>
                <span class="user"> {{ title }}</span>
              </div>
            </div>
            <div class="user-infos">
              <div class="info">
                <span class="title">Oyuncu ID:</span>
                <span class="user"> {{ return_id }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-menu-toggle" :class="{'toggle-show' : toggleOpen}">
      <div class="container">
        <div class="row no-gutters">
          <div class="col-md-12">
            <ul>
              <li><router-link :to="{path: '/orders/'+apiKey}" ><span class="ico"><i class="fas fa-history"></i></span><span
                  class="title">Siparişlerim</span></router-link></li>
              <li><router-link :to="{path: '/history/'+apiKey}" ><span class="ico"><i class="icon-history"></i></span><span
                  class="title">Geçmiş Ödemelerim</span></router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>

</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "Header",
  props: ['title', 'return_id', 'apiKey', 'page'],
  data() {
    return {
      toggleOpen: false,
      balanceLoading: false,
      walletBalance: 0
    }
  },
  methods: {
    toggle() {
      if(this.toggleOpen)
        this.toggleOpen = false
      else
        this.toggleOpen = true
    },
    getToken() {
      this.balanceLoading = true
      apiService.getToken()
      .then((res) => {
        this.balanceLoading = false
        if(res.state){
          this.walletBalance = res.user.balance
        }else{
          this.$store.dispatch('updateUser', {
            token: '',
            balance: 0,
            login: false,
          })
        }
      })
    }
  },
  created() {
    if(this.$store.getters.user.login){
      this.getToken()
    }
  }
}
</script>

<style scoped>
.toggle-show {
  display: block;
  visibility: inherit;
}
.hover {
  color: #cb0753;
}
</style>