<template>
  <footer class="footer-style-3">
    <div class="footer-bottom footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <div class="copyright-left">
              <p>Decade Bilişim LTD. Serbest Liman ve Bölge , Posta Kutusu No : 568 Gazimağusa / Kıbrıs</p>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="copyright-right">
              <p>© 2022 kasagame.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>