import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {
            token: '',
            balance: 0,
            login: false,
            username: '',
            profilePic: ''
        },
        refresh: {},
        wallet: false,
        productLikes: []
    },
    mutations: {
        commitUser (state, data) {
            state.user = data
        },
        setRefresh(state, payLoad){
            state.refresh = payLoad
        },
        setWallet(state, payLoad){
            state.wallet = payLoad
        },
        setProductLike(state, payLoad){
            state.productLikes = payLoad
        },

    },
    actions: {
        updateUser (context, payload) {
            context.commit('commitUser', payload)
        },
        getRefresh(context, payLoad) {
            context.commit('setRefresh', payLoad)
        },
        getWallet(context, payLoad) {
            context.commit('setWallet', payLoad)
        },
        getLikes(context, payLoad) {
            context.commit('setProductLike', payLoad)
        },
    },
    getters: {
        user: state => state.user,
        wallet: state => state.wallet,
        getRefresh: state => state.refresh,
        productLikes: state => state.productLikes
    },
})
