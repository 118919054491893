<template>
  <div>
    <header class="main-header position-absolute w-100">
      <nav class="navbar navbar-expand-xl navbar-dark sticky-header ">
        <div class="container d-flex align-items-center justify-content-lg-between position-relative">
          <router-link class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none" :to="{path: '/'+apiKey}">
            <img :src="require('../assets/images/kasagame-logo.svg')" alt="logo" class="img-fluid" />
          </router-link>
          <a class="navbar-toggler position-absolute right-0 border-0" href="#offcanvasWithBackdrop" role="button">
            <span class="fas fa-bars" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop"></span>
          </a>
          <div class="collapse navbar-collapse justify-content-center">
            <ul class="nav col-12 col-md-auto justify-content-center main-menu">
              <li>
                <router-link class="nav-link" :to="{path: '/orders/'+apiKey}" active-class="bg-dark text-white">
                <span class="ico">
                        <i class="fa fa-check-circle"></i>
                </span>
                  Siparişlerim
                </router-link>
              </li>
              <li>
                <router-link class="nav-link" :to="{path: '/history/'+apiKey}" active-class="bg-dark text-white">
                <span class="ico">
                        <i class="fa fa-history"></i>
                </span>
                  Geçmiş Ödemelerim
                </router-link>
              </li>
            </ul>
          </div>
          <div class="action-btns d-flex align-items-center text-end me-5 me-xl-0 d-none d-sm-block d-md-flex d-lg-flex">
            <div class="primary-btn ms-3" v-if="!this.$store.getters.user.login">
              <a v-b-modal.wallet-login class="primary-btn d-none d-md-block" >Kasagame Cüzdan</a>
            </div>
            <div v-else class="primary-btn">
              <a  class="primary-btn d-none d-md-block">
                <span class="name" >{{ $store.getters.user.username }}</span>
              <div>
                  <span class="balance" v-if="balanceLoading"><b-skeleton width="100%"></b-skeleton></span>
                  <span class="balance" v-else>{{ walletBalance | currency}} TL</span>
                  <span class="add" @click="addBalance()"><i class="fas fa-plus-circle" ></i></span><br>
                  <b-button variant="danger" @click="logout"> Çıkış Yap</b-button>
              </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasWithBackdrop" aria-labelledby="offcanvasRightLabel">
      <div class="offcanvas-header">
        <h5 id="offcanvasRightLabel">
          <a class="navbar-brand d-flex align-items-center mb-md-0 text-decoration-none" href="/">
            <img :src="require('../assets/images/kasagame-logo.svg')" alt="logo" class="img-fluid"/>
          </a>
        </h5>
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
                                <span>
                                    <i class="fa-solid fa-xmark"></i>
                                </span>
        </button>
      </div>
      <div class="offcanvas-body">
        <ul class="nav col-12 col-md-auto justify-content-center main-menu">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Home</a>
            <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
              <div class="dropdown-grid rounded-custom width-half">
                <div class="dropdown-grid-item">
                  <a class="dropdown-link demo-link" href="/">
                                                    <span class="demo-list secondary-bg rounded-circle text-white">1
                                                      <!-- -->
                                                    </span>
                    <div class="dropdown-info">
                      <div class="drop-title">NFT Auction Home</div>
                    </div>
                  </a>
                  <a class="dropdown-link demo-link" href="/home-two">
                                                    <span class="demo-list secondary-bg rounded-circle text-white">2
                                                      <!-- -->
                                                    </span>
                    <div class="dropdown-info">
                      <div class="drop-title">Feature Category Home</div>
                    </div>
                  </a>
                  <a class="dropdown-link demo-link" href="/home-three">
                                                    <span class="demo-list secondary-bg rounded-circle text-white">3
                                                      <!-- -->
                                                    </span>
                    <div class="dropdown-info">
                      <div class="drop-title">Counter Slider Home</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a class="nav-link" href="/activity">Activity</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Collectors</a>
            <div class="dropdown-menu border-0 rounded-custom shadow py-0">
              <div class="dropdown-grid rounded-custom width-full-3">
                <div class="dropdown-grid-item first-item">
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img :src="require('../assets/images/mega-thumb-1.png')" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Trading Card</h6>
                      <span>140 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-2.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Domain names</h6>
                      <span>125 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-3.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Photography</h6>
                      <span>135 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-5.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Collectibles</h6>
                      <span>155 items</span>
                    </div>
                  </a>
                </div>
                <div class="dropdown-grid-item second-item pt-0">
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-4.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>virtual Worlds</h6>
                      <span>140 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-5.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Collections</h6>
                      <span>146 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-6.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Trading Card</h6>
                      <span>170 items</span>
                    </div>
                  </a>
                  <a class="mega-list-item d-flex align-items-center" href="/explore-collections">
                    <img src="/img/mega-thumb-2.png" alt="collector" class="img-fluid rounded-circle"/>
                    <div class="right-content">
                      <h6>Solana NFTs</h6>
                      <span>160 items</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">Blog</a>
            <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-dark">
              <div class="dropdown-grid rounded-custom width-half">
                <div class="dropdown-grid-item">
                  <a class="dropdown-link px-0" href="/blog-grid">
                    <span class="drop-title">Blog Grid</span>
                  </a>
                  <a class="dropdown-link px-0" href="/blog-list">
                    <span class="drop-title">Blog List</span>
                  </a>
                  <a class="dropdown-link px-0" href="/blog-details">
                    <span class="drop-title">Blog Details</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Pages</a>
            <div class="dropdown-menu border-0 rounded-custom shadow py-0 bg-white">
              <div class="dropdown-grid rounded-custom width-full">
                <div class="dropdown-grid-item">
                  <a class="dropdown-link px-0" href="/collections">
                    <span class="drop-title">Collections</span>
                  </a>
                  <a class="dropdown-link px-0" href="/explore-collections">
                    <span class="drop-title">Exlplore Collections</span>
                  </a>
                  <a class="dropdown-link px-0" href="/wallet">
                    <span class="drop-title">Wallets</span>
                  </a>
                  <a class="dropdown-link px-0" href="/create-item">
                    <span class="drop-title">Create Item</span>
                  </a>
                  <a class="dropdown-link px-0" href="/how-it-works">
                    <span class="drop-title">How It Works</span>
                  </a>
                  <a class="dropdown-link px-0" href="/creators">
                    <span class="drop-title">Creators</span>
                  </a>
                  <a class="dropdown-link px-0" href="/creator-profile">
                    <span class="drop-title">Creator Profile</span>
                  </a>
                  <a class="dropdown-link px-0" href="/product-details">
                    <span class="drop-title">Item Details</span>
                  </a>
                  <a class="dropdown-link px-0" href="/product-auction-details">
                    <span class="drop-title">Auction Item Details</span>
                  </a>
                  <a class="dropdown-link px-0" href="/live-auction">
                    <span class="drop-title">Live Auction</span>
                  </a>
                  <a class="dropdown-link px-0" href="/faq">
                    <span class="drop-title">FAQ</span>
                  </a>
                  <a class="dropdown-link px-0" href="/contact-us">
                    <span class="drop-title">Contact Us</span>
                  </a>
                  <a class="dropdown-link px-0" href="/404">
                    <span class="drop-title">404 Page</span>
                  </a>
                </div>
              </div>
            </div>
          </li>
          <li>
            <a class="nav-link" href="/contact-us">Contact</a>
          </li>
        </ul>
      </div>
    </div>
    <WalletLogin></WalletLogin>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import WalletLogin from "@/components/WalletLogin";
export default {
  name: "Header",
  props: ['title', 'return_id', 'apiKey', 'page'],
  components: {WalletLogin},
  data() {
    return {
      toggleOpen: false,
      balanceLoading: false,
      walletBalance: 0
    }
  },
  methods: {
    toggle() {
      if(this.toggleOpen)
        this.toggleOpen = false
      else
        this.toggleOpen = true
    },
    getToken() {
      this.balanceLoading = true
      apiService.getToken()
          .then((res) => {
            this.balanceLoading = false
            if(res.state){
              this.walletBalance = res.user.balance
            }else{
              this.$store.dispatch('updateUser', {
                token: '',
                balance: 0,
                login: false,
              })
            }
          })
    },
    addBalance(){
      window.location.href= 'https://kasagame.com/login?token='+this.$store.getters.user.token
    },
    logout(){
      this.$store.dispatch('updateUser', {
        token: '',
        balance: 0,
        login: false,
      })
    }
  },
  created() {
    if(this.$store.getters.user.login){
      this.getToken()
    }
  },
  watch: {
    '$store.getters.user': function() {
      if(this.$store.getters.user.login){
        this.getToken()
      }
    },
  }
}
</script>

<style scoped>
.hover {
  color: #cb0753;
}
</style>