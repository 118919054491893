<template>
  <div class="main-wrapper">
    <header-new :title="gameDetail.title" :return_id="gameDetail.return_id" :apiKey="key" page="history"></header-new>
    <section class="nft-activity-area pt-120 pb-120 position-relative overflow-hidden">
      <img :src="require('../assets/images/shapes/hero-top-left.svg')" alt="shape" class="shape-top-left position-absolute">
      <div class="container">
        <div class="row">
          <h3>Sipariş Geçmişi</h3>
          <div class="col-lg-12">
            <div class="activity-list">
              <div class="table-responsive">
                <table class="table table-striped table-bordered align-middle">
                  <thead>
                  <tr>
                    <th>Ödeme Yöntemi</th>
                    <th>Sipariş Bilgisi</th>
                    <th>Tutar</th>
                    <th>Ödeme Durumu</th>
                    <th>Tarih</th>
                  </tr>
                  </thead>
                  <tbody v-if="loading">
                  <tr v-for="(index) in 1" :key="index">
                    <td class="item-info d-flex align-items-center" >
                      <h6 v-if="index === 1"><i class="fas fa-spinner fa-spin text-info"></i></h6>
                    </td>
                    <td class=" ">
                      <h6 v-if="index === 1"><i class="fas fa-spinner fa-spin text-info"></i></h6>
                    </td>
                    <td class=" ">
                      <h6 v-if="index === 1"><i class="fas fa-spinner fa-spin text-info"></i></h6>
                    </td>
                    <td>
                      <h6 v-if="index === 1"><i class="fas fa-spinner fa-spin text-info"></i></h6>

                    </td>
                    <td>
                      <h6 v-if="index === 1"><i class="fas fa-spinner fa-spin text-info"></i></h6>

                    </td>
                  </tr>
                  </tbody>
                  <tbody v-else>
                  <tr v-if="!orders">
                    <td class="text-danger"> Kayıt bulunamadı. </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr v-for="(item, index) in orders" :key="index">
                    <td class="item-info d-flex align-items-center" >
                      <h6 v-if="item.payment">{{ item.payment.title }}</h6>
                    </td>
                    <td class=" ">
                      <div class="item-content ms-3"><span>#{{ item.id }}</span>
                        <h6>{{ item.product.title }}</h6>
                      </div>
                    </td>
                    <td class="item-info d-flex align-items-center" >
                      <h6>{{ item.price | currency }} TL</h6>
                    </td>

                    <td>
                      <span class="text-success"  v-if="item.status === '1'"> <i class="fas fa-check-circle"></i> Sipariş Başarılı.</span>
                      <span class="text-danger" v-if="item.status === '2'"> <i class="fas fa-cancel"></i> Sipariş Başarısız.</span>
                      <span class="text-info" v-if="item.status === '0'"> <i class="fas fa-spinner fa-spin"></i> Onay Bekleniyor.</span>
                      <span class="text-primary" v-if="item.status === '3'"> <i class="fas fa-spinner fa-spin"></i> Ödeme Bekleniyor.</span>
                    </td>
                    <td>
                      <div class="time text-center"><span>{{ item.sale_date | formatDate }}</span></div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img :src="require('../assets/images/shapes/hero-top-right.svg')" alt="shape" class="shape-right-top position-absolute">
      <img :src="require('../assets/images/shapes/hero-top-right.svg')" alt="shape" class="right-0 shape-bottom position-absolute">
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import HeaderNew from "@/components/HeaderNew";
import Footer from "@/components/Footer";
export default {
  name: "Home",
  data() {
    return {
      key: this.$route.params.key,
      keyErr : false,
      gameDetail: {
        title: '',
        products: [],
      },
      loading: true,
      orders: []

    }
  },
  components: { HeaderNew, Footer },
  methods: {
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    checkKey() {
      apiService.checkPaymentLink({key: this.key})
          .then((res) => {
            if(res.state) {
              this.keyErr = false
            }
            else {
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    gameDetails() {
      apiService.gameDetail({key: this.key})
          .then((res) => {
            if(res.state){
              this.gameDetail = res.data
            }else{
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    getOrders() {
      this.loading = true
      apiService.getOrders({key: this.key})
      .then((res) => {
        if(res.state){
          this.orders = res.data
          this.loading = false
        }else{
          this.errorMessage(res.message)
          setTimeout(() => {
            this.$router.push({path : '/'+this.key })
          }, 1500)
        }
      })
    }
  },
  created() {
    this.key = this.$route.params.key
    this.checkKey()
    this.gameDetails()
    this.getOrders()
  },
  watch: {
    keyErr : function () {
      if(this.keyErr)
        setTimeout(() => {
          window.location.href = 'https://kasagame.com'
        }, 1500)
    }
  }
}
</script>

<style scoped>

</style>