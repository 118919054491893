<template>
  <div >
    <div class="product-step-content">
      <div class="container">
        <div class="row no-gutters">

          <div class="col-4 col-md-4" style="display: block;">
            <div class="item">
              <router-link :to="{path: '/'+apiKey}">
              <div class="status " :class="{'now' : step === '1', 'checked': productSelected}">
                <div class="checked"><i class="fas fa-check"></i></div>
                <div class="now"><i></i></div>
                <div class="waiting"></div>
              </div>
              <div class="text">
                <span class="step" v-if="productSelected"> ÜRÜN SEÇİLDİ </span>
                <span class="step" v-else>Seçim Yapın</span>
                <span class="method" v-if="productSelected"> {{ productDetail.title }} - {{ productDetail.price | currency }} TRY</span>
                <span class="method" v-else>ÜRÜN SEÇİN</span>
              </div>
            </router-link>
            </div>
          </div>

          <div class="col-4 col-md-4" style="display: block;">
            <div class="item">
              <router-link :to="{path: '/payment-methods/'+product_id+'/'+apiKey}" v-if="productSelected">
                <div class="status" :class="{'now' : step === '2', 'waiting': step === '1' , 'checked': step === '3'}">
                  <div class="checked"><i class="fas fa-check"></i></div>
                  <div class="now"><i></i></div>
                  <div class="waiting"></div>
                </div>
                <div class="text">
                  <span class="step" v-if="paymentChannelSelected"> {{channelDetail.title}} </span>
                  <span class="step" v-else>Seçim Yapın</span>
                  <span class="method">ÖDEME YÖNTEMİ</span>
                </div>
              </router-link>
              <router-link :to="{path: '/'+apiKey}" v-else>
                <div class="status waiting">
                  <div class="checked"><i class="icon-ok"></i></div>
                  <div class="now"><i></i></div>
                  <div class="waiting"></div>
                </div>
                <div class="text">
                  <span class="step">Seçim Yapın</span>
                  <span class="method">ÖDEME YÖNTEMİ</span>
                </div>
              </router-link>
            </div>
          </div>

          <div class="col-4 col-md-4">
            <div class="item last"><a
                href="#">
              <div class="status" :class="{'waiting' : step != '3', 'now': step === '3'}">
                <div class="checked"><i class="icon-ok"></i></div>
                <div class="now"><i></i></div>
                <div class="waiting"></div>
              </div>
              <div class="text"><span class="step">Son Adım</span>
                <span class="method">TAMAMLAYIN</span></div>
            </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "Menu",
  data() {
    return {
      productSelected: false,
      productDetail: {},
      paymentChannelSelected: false,
      channelDetail: {
        title: ''
      },
    }
  },
  props: ['step', 'apiKey', 'product_id', 'method_id'],
  methods: {
    getPaymentChannelDetail() {
      apiService.paymentChannelDetail({id: this.method_id, key: this.apiKey})
      .then((res) => {
        if(res.state){
          this.paymentChannelSelected = true
          this.channelDetail = res.data[0]
        }else{
          this.$router.push({path: '/'+this.apiKey})
        }
      })
    }
  },
  created() {
    if(this.step === '3'){
      this.getPaymentChannelDetail()
    }
    if(this.step === '2' || this.step === '3'){
      if(this.product_id){
        apiService.checkProductId({id: this.product_id, key: this.apiKey})
        .then((res) => {
          if(res.state){
            this.productSelected = true
            this.productDetail = res.data[0]
          }else{
            this.$router.push({path: '/'+this.apiKey})
          }
        })
      }else{
        this.productSelected = false
        this.$router.push({path: '/'+this.apiKey})
      }
    }
  },
  watch: {

  }
}
</script>

<style scoped>

</style>