<template>
  <div>
    <div>
      <Header :title="gameDetail.title" :return_id="gameDetail.return_id" :apiKey="key"></Header>
      <Menu :apiKey="key"></Menu>
      <div>
        <div class="container">
          <div class="row" >
            <div class="col-md-4" >
              <div class="order-summary-content" >
                <div class="heading" >Hesap Özeti</div>
                <div class="item" >
                  <span class="head" >Kullanılabilir Bakiyeniz</span>
                  <div class="price" >
                    <span class="count" >{{ walletBalance | currency }}</span>
                    <span class="currency" >TL</span>
                  </div>
                </div>
                <div class="choose back" >
                  <router-link class="btn" :to="{path: '/'+key}"> <i class="fas fa-cart-arrow-down" /> Ürünler Ekranına Geri Dön</router-link>
                </div>
              </div>
            </div>
            <div class="col-md-8" >
              <div class="form-content "  v-if="this.$store.getters.user.login">
                <br>
                <b-card>
                  <b-card-header>
                    Hoşgeldin; <strong>{{ $store.getters.user.username }} </strong><br>
                    <p class="text-danger" @click="logout"><i class="fas fa-power-off"></i> Çıkış Yap</p>

                    <div class="notif-alert success mtop"><b>Cüzdan Hesabına bakiye yüklemek için <a href="https://kasagame.com/login" target="_blank"> Sitemiz </a> üzerinden hesabın ile giriş yapıp bakiye yüklemelisin.</b>: </div>
                  </b-card-header>
                </b-card>
              </div>
              <div class="form-content "  v-else>
                <br>
                <form  @submit.prevent="signIn">
                  <div class="payment-report validation_form paywant-content" >
                    <div class="row " >
                      <div class="col-md-12" >
                        <div class="notif-alert success mtop">
                          Kasagame cüzdan ile ödeme yapabilmek için lütfen hesabınız ile giriş yapın.
                        </div>
                        <div class="notif-alert warning mtop"><b>Kasagame hesabın yoksa <a href="https://kasagame.com/register" target="_blank">Buraya Tıklayarak</a> sitemiz üzerinden kayıt olabilirsin.</b>: </div>

                        <div class="row" style="padding-top: 15px;">
                          <div class="col-md-6">
                            <b-form-group label="Eposta Adresiniz">
                              <b-input v-model="email" placeholder="Kasagame email adresiniz"></b-input>
                            </b-form-group>
                          </div>
                          <div class="col-md-6">
                            <b-form-group label="Şifreniz">
                              <b-input v-model="password" type="password" placeholder="Şifreniz"></b-input>
                            </b-form-group>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12" ><br>
                        <button type="submit" class="bttn bttn-lg bttn-block bttn-success" >Giriş Yap</button>
                      </div>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span></span>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import Header from "@/components/Header";
import Menu from "@/components/Menu";
export default {
  name: "Home",
  data() {
    return {
      key: this.$route.params.key,
      keyErr : false,
      gameDetail: {
        title: '',
        products: [],
      },
      loading: true,
      orders: [],
      walletBalance: 0,
      email: '',
      password: ''
    }
  },
  components: { Header, Menu },
  methods: {
    getToken() {
      apiService.getToken()
          .then((res) => {
            if(res.state){
              this.walletBalance = res.user.balance
            }else{
              this.$store.dispatch('updateUser', {
                token: '',
                balance: 0,
                login: false,
              })
            }
          })
    },
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    checkKey() {
      apiService.checkPaymentLink({key: this.key})
          .then((res) => {
            if(res.state) {
              this.keyErr = false
            }
            else {
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    gameDetails() {
      apiService.gameDetail({key: this.key})
          .then((res) => {
            if(res.state){
              this.gameDetail = res.data
            }else{
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    signIn() {
      apiService.userLogin({email: this.email, password: this.password})
          .then((res) => {
            if(res.state){
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'success',
                title: 'Başarıyla giriş yaptınız',
                text: 'yönlendiriliyorsunuz..',
              });
              this.$store.dispatch('updateUser', {
                token: res.token,
                balance: res.user.balance,
                username: res.user.username,
                profilePic: res.user.profilePic,
                login: true,
              })
              this.$router.push({path: '/'+this.key})
            }else {
              this.$swal({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: '',
                text: res.message,
              });
            }
          })
    },
    logout(){
      apiService.logout()
      .then(() => {
        this.$router.push({path: '/'+this.key})
      })
    }
  },
  created() {
    this.key = this.$route.params.key
    this.checkKey()
    this.gameDetails()
    if(this.$store.getters.user.login){
      this.getToken()
    }

  },
  watch: {
    keyErr : function () {
      if(this.keyErr)
        setTimeout(() => {
          window.location.href = 'https://kasagame.com'
        }, 1500)
    }
  }
}
</script>

<style scoped>

</style>