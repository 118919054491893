import Vue from 'vue';
import VueRouter from 'vue-router';
import Wallet from "@/views/Wallet";
import HomeNew from "@/views/HomeNew";
import PaymentMethodsNew from "@/views/PaymentMethodsNew";
import PayNew from "@/views/PayNew";
import HistoryNew from "@/views/HistoryNew";
import OrdersNew from "@/views/OrdersNew";

Vue.use(VueRouter);

const routes = [
    {
        path: '/:key',
        name : 'Home',
        component: HomeNew
    },
    {
        path: '/payment-methods/:id/:key',
        name: 'PaymentMethods',
        component: PaymentMethodsNew
    },
    {
        path: '/pay/:product_id/:id/:key',
        name: 'pay',
        component: PayNew
    },
    {
        path: '/orders/:key',
        name: 'orders',
        component: OrdersNew
    },
    {
        path:'/history/:key',
        name: 'history',
        component: HistoryNew
    },
    {
        path:'/wallet/:key',
        name: 'wallet',
        component: Wallet
    }
    ]

const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});
export default router
