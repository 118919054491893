<template>
  <div>
    <div class="main-wrapper">
      <header-new :title="gameDetail.title" :return_id="gameDetail.return_id" :apiKey="key"></header-new>
      <section class="nft-wallet-list pt-120 pb-90" :style="{'background-image': 'url('+require('../assets/images/login-bg.png')+')'} ">
        <div class="container">
          <div class="row">
            <h1 class="text-white"> Ödeme Kanalı Seçin </h1>
            <div class="col-lg-6">
              <div class="wallet-lists" v-if="loading">
                <a href="#" v-for="index in 6" :key="index">
                  <div class="single-wallet">
                    <div class="wallet-left">
                      <b-skeleton width="400px"></b-skeleton>
                    </div>
                  </div>
                </a>
              </div>

              <div class="wallet-lists">
                <div v-for="(item, index) in paymentChannels" :key="index">
                  <a   v-if="item.id === '9' || item.id === '10'" @click="currencyApprove({id: item.id})">
                    <div class="single-wallet">
                      <div class="wallet-left">
                        <div class="wallet-title">
                          <h5>{{  item.title }}</h5>
                        </div>
                      </div>
                      <div class="wallet-label" v-if="campaigns.length > 0">
                      <span v-for="(campaignItem, index) in campaigns[0].payment_methods" :key="index">
                        <div v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün '+item.title+' yönteminde kampanya dahilinde '+ productDetail.price+ 'TL yerine '+campaigns[0].price+' TL' " v-if="campaignItem.payment_id === item.id && productDetail.price != campaigns[0].price">
                          <del>{{ productDetail.price | currency}} TL</del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].price | currency}} TL
                        </div>
                        <div  v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün ile bu ödeme yönteminde '+(campaigns[0].extra_data-productDetail.extra_data)+' oyun kredisi daha kazançlı olursunuz!' " v-if="campaignItem.payment_id === item.id && productDetail.extra_data != campaigns[0].extra_data">
                          <del>{{ productDetail.extra_data}} </del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].extra_data }} Oyun kredisi
                        </div>
                      </span>
                      </div>
                    </div>
                  </a>
                  <a  v-else-if="item.id === '11'" @click="wallet({id: item.id, commission: item.commission})">
                    <div class="single-wallet">
                      <div class="wallet-left">
                        <div class="wallet-title">
                          <h5>{{  item.title }}</h5>
                        </div>
                      </div>
                      <div class="wallet-label" v-if="campaigns.length > 0">
                      <span v-for="(campaignItem, index) in campaigns[0].payment_methods" :key="index">
                        <div v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün '+item.title+' yönteminde kampanya dahilinde '+ productDetail.price+ 'TL yerine '+campaigns[0].price+' TL' " v-if="campaignItem.payment_id === item.id && productDetail.price != campaigns[0].price">
                          <del>{{ productDetail.price | currency}} TL</del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].price | currency}} TL
                        </div>
                        <div  v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün ile bu ödeme yönteminde '+(campaigns[0].extra_data-productDetail.extra_data)+' oyun kredisi daha kazançlı olursunuz!' " v-if="campaignItem.payment_id === item.id && productDetail.extra_data != campaigns[0].extra_data">
                          <del>{{ productDetail.extra_data}} </del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].extra_data }} Oyun kredisi
                        </div>
                      </span>
                      </div>
                    </div>
                  </a>
                  <router-link :to="{path: '/pay/'+id+'/'+item.id+'/'+key}"  v-else >
                    <div class="single-wallet">
                      <div class="wallet-left">
                        <div class="wallet-title">
                          <h5>{{  item.title }}</h5>
                        </div>
                      </div>
                      <div class="wallet-label" v-if="campaigns.length > 0">
                      <span v-for="(campaignItem, index) in campaigns[0].payment_methods" :key="index">
                        <div v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün '+item.title+' yönteminde kampanya dahilinde '+ productDetail.price+ 'TL yerine '+campaigns[0].price+' TL' " v-if="campaignItem.payment_id === item.id && productDetail.price != campaigns[0].price">
                          <del>{{ productDetail.price | currency}} TL</del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].price | currency}} TL
                        </div>
                        <div  v-b-tooltip:v-b-hover :title="'Seçtiğiniz ürün ile bu ödeme yönteminde '+(campaigns[0].extra_data-productDetail.extra_data)+' oyun kredisi daha kazançlı olursunuz!' " v-if="campaignItem.payment_id === item.id && productDetail.extra_data != campaigns[0].extra_data">
                          <del>{{ productDetail.extra_data}} </del>
                          <i class="fas fa-gift"/>
                          {{ campaigns[0].extra_data }} Oyun kredisi
                        </div>
                      </span>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import HeaderNew from "@/components/HeaderNew";
import Footer from "@/components/Footer";
export default {
  name: "PaymentMethods",
  data() {
    return {
      key: this.$route.params.key,
      id: this.$route.params.id,
      keyErr : false,
      gameDetail: {
        title: '',
        products: [],
      },
      paymentChannels: [],
      productDetail: {},
      loading: true,
      campaigns: []
    }
  },
  components: { HeaderNew, Footer },
  methods: {
    successMessage(val) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: val,
        text: '',
      });
    },
    checkCredit(val){
      let find = val.methods.findIndex(e => e.payment_id === val.id)
      if(find > -1){
        return false
      }else{
        return true
      }
    },
    errorMessage(val) {
      this.$swal({
        showConfirmButton: true,
        icon: 'error',
        title: 'HATA',
        text: val,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    checkKey() {
      apiService.checkPaymentLink({key: this.key})
          .then((res) => {
            if(res.state) {
              this.keyErr = false
            }
            else {
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    gameDetails() {
      apiService.gameDetail({key: this.key})
          .then((res) => {
            if(res.state){
              this.gameDetail = res.data
            }else{
              this.errorMessage(res.message)
              this.keyErr = true
            }
          })
    },
    paymentMethods() {
      this.loading = true
      apiService.paymentMethods({id: this.id, key:this.key})
      .then((res) => {
        if(res.state){
          this.loading = false
          let findWallet = res.data.findIndex(e => e.id === '11')
          if(findWallet > -1){
            this.$store.dispatch('getWallet', true)
          }else{
            this.$store.dispatch('getWallet', false)
          }
          res.data.map((item) => {
            if(item.commission > 0){
              let commission = (100) - (item.commission);
              let price = parseFloat(this.productDetail.price)/(commission)*(100)
              if(item.maximum >= price ){
                this.paymentChannels.push(item)
              }
            }else{
              this.paymentChannels.push(item)
            }
          })
        }else{
          this.errorMessage(res.message)
          setTimeout(() => {
            this.$router.push({path: '/' + this.key})
          }, 1500)
        }
      })
    },
    getCampaigns() {
        apiService.campaigns({key: this.key, id: this.id})
            .then((res) => {
              if (res.state) {
                this.campaigns = res.data
              }
            })
    },
    getProductDetail() {
      apiService.checkProductId({id: this.id, key: this.key})
          .then((res) => {
            if(res.state){
              this.productDetail = res.data[0]
            }else{
              this.$router.push({path: '/'+this.apiKey})
            }
          })
    },
    currencyApprove(data){
      this.$swal({
        text: "Bulunduğunuz ülkenin yasal mevzuatlarına (vergilendirme, komisyon vb.) göre ödeme ekranındaki tutar değişiklik gösterebilir. Devam etmek istediğinize emin misiniz?",
        title: "Uyarı",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Devam Et",
        cancelButtonText: "Vazgeç"
      }).then((result) => {
        if(result.isConfirmed){
          this.$router.push({ path: '/pay/'+this.id+'/'+data.id+'/'+this.key})
        }
      })

    },
    wallet(data){
      if(this.$store.getters.user.login){
        let amount = this.productDetail.price
        if(data.commission > 0){
          const commission = (100) - (data.commission);
          amount = parseFloat(amount)/(commission)*(100);
        }
        this.$swal({
          title: "Kasagame Cüzdan",
          text: amount+" TRY hesabınızdan düşürülüp, sipariş onaylanacaktır.Emin misiniz?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: "Devam Et",
          cancelButtonText: "Vazgeç"
        }).then((result) => {
          if(result.isConfirmed){
            this.$router.push({ path: '/pay/'+this.id+'/'+data.id+'/'+this.key})
          }
        })
      }else{
        this.$bvModal.show('wallet-login')
      }
    },
    commission(data){
      const commission = (100) - (data.commission);
      const price = parseFloat(data.amount)/(commission)*(100);
      return price
    }
  },
  created() {
    this.key = this.$route.params.key
    this.checkKey()
    this.gameDetails()
    this.paymentMethods()
    this.getProductDetail()
    this.getCampaigns()
  },
  watch: {
    keyErr : function () {
      if(this.keyErr)
        setTimeout(() => {
          window.location.href = 'https://kasagame.com'
        }, 1500)
    }
  }
}
</script>

<style scoped>

</style>